import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderInfo } from '../../actions/orderInfo';
import { getMembershipPlans, setForceMember } from '../../actions/membership';
import { createAllMembershipPlanSelector } from '../../reducers/memberships';
import { displayCurrency } from '../../helpers/currency';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';
import { IconButton } from '@material-ui/core';
import Avatar from '../../components/Avatar';

const content = {
  'badge': '',
  'header-p1': 'สมาชิก',
  // 'header-p2': 'turpis non sapien lobortis pretium',
  'description': 'ปรึกษาแพทย์ ได้ตลอด 24/7 พร้อมสิทธิประโยชน์อีกมากมายให้คุณเข้าถึงแพทย์ทุกแผนกได้แบบไม่จำกัด รวมไปถึงวิตามิน อาหารเสริมที่ออกแบบมาเพื่อคุณ',
  'option1': 'Monthly',
  'option2': 'Annual',
  '01_badge': 'badge_standard',
  '01_title': 'Standard Package',
  '01_price': '1,990.-',
  '01_suffix': ' / ปี',
  '01_benefit1': 'ปรึกษาแพทย์ไม่จำกัดครั้ง',
  '01_benefit2': 'ค่ายา มูลค่า 1,200 บาท',
  '01_primary-action': 'เลือก Package',
  // '01_secondary-action': 'Learn more',
  '02_badge': 'badge_gold',
  '02_title': 'Gold Package',
  '02_price': '5,990.-',
  '02_suffix': ' / ปี',
  '02_benefit1': 'ปรึกษาแพทย์ไม่จำกัดครั้ง',
  '02_benefit2': 'วิตามิน อาหารเสริม มูลค่า 1,500 บาท',
  '02_benefit3': 'โปรแกรมตรวจสุขภาพ มูลค่า 2,000 บาท',
  '02_benefit4': 'ปรึกษาผู้เชี่ยวชาญด้าน wellness 6 ครั้ง ',
  '02_primary-action': 'เลือก Package',
  // '02_secondary-action': 'Learn more',
  '03_badge': 'badge_platinum',
  '03_title': 'Platinum Package',
  '03_price': '9,990.-',
  '03_suffix': ' / ปี',
  '03_benefit1': 'ปรึกษาแพทย์ไม่จำกัดครั้ง',
  '03_benefit2': 'วิตามิน อาหารเสริม มูลค่า 2,000 บาท',
  '03_benefit3': 'โปรแกรมตรวจสุขภาพ มูลค่า 5,000 บาท',
  '03_benefit4': 'ปรึกษาผู้เชี่ยวชาญด้าน wellness 12 ครั้ง ',
  '03_primary-action': 'เลือก Package',
  // '03_secondary-action': 'Learn more',
};
function getAsset(val) {
  const res = val.toLowerCase().replaceAll(' ', '');
  if (res.includes('gold')) return 'badge_goldpackage.svg';
  if (res.includes('standard')) return 'badge_standardpackage.svg';
  if (res.includes('platinum')) return 'badge_platinumpackage.svg';
  return 'badge_goldpackage.svg';
}

export default function Pricing({ location }) {
  const dispatch = useDispatch()
  const session = useSelector(state => state.session);
  const plans = useSelector(createAllMembershipPlanSelector);
  const { force, cardId } = useSelector(state => state.memberships);

  React.useEffect(() => {
    dispatch(getMembershipPlans());
  }, []);

  React.useEffect(() => {
    if (!force) {
      if (session?.user?.is_member) {
        navigate('/membership' + location.search, { replace: true });
      }
      dispatch(setForceMember(false));
    }
  }, [session]);

  const onClick = (plan, buyFor) => {
    const { cardtype, published } = plan;
    window.logEvent('select_content', { content_type: 'membership_package', item_id: cardtype?.id, item_name: cardtype?.name });
    if (published) {
      dispatch(setOrderInfo({ plan: plan, buyFor: buyFor }));
      navigate(`/otp2?returnUri=/membership/checkout`);
    } else {
      alert('ขออภัยค่ะ ขณะนี้สินค้าหมด');
    }
  }

  return (
    <Layout
      bg="bg1"
      title={content['header-p1']}
      description={content['description']}
    >
      <Box>
        {/* <img src={require(`../../assets/images/gift.svg`)} /><Avatar variant='square' src={require(`../../assets/images/gift.svg`)} /> */}
        <Button
          variant='outlined'
          style={{ marginBottom: '15px' }}
          startIcon={<img src={require(`../../assets/images/gift.svg`)} />}
          onClick={() => navigate('/membership/redeem')}
        >{'กรอกโค้ดรับของขวัญ'}</Button>
      </Box>
      <Grid container spacing={3}>
        {
          plans.map((val, index) => {
            const { cardtype, membership_benefits } = val;
            return (
              <Grid item xs={12} md={4} key={index}>
                <Card variant="outlined">
                  <CardContent>
                    <Box textAlign="center">
                      <img src={require(`../../assets/images/${getAsset(cardtype?.name)}`)} />
                      <Typography variant="h6" component="h1">
                        {cardtype?.name}
                      </Typography>
                      <Typography variant="h5" component="h2">{val.price}</Typography>
                      <Typography component="small" gutterBottom>
                        {val.subtitle}
                      </Typography>
                      <Divider />
                      <Box my={1}>
                        <Typography variant="h6" component="h2">
                          {'สิทธิประโยชน์'}
                        </Typography>
                        {
                          val.membership_benefits.map((val1, index1) => {
                            return (
                              <Typography key={index1} variant="subtitle1" component="p">{val1.detail}</Typography>
                            )
                          })
                        }
                      </Box>
                      {/* <Button onClick={onClick(val)}>{'เลือก Package'}</Button> */}
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <Box flex={1}>
                          <Button
                            fullWidth
                            variant={Object.keys(cardId).length !== 0 ? 'contained' : 'outlined'}
                            size='large'
                            onClick={() => onClick(val, 'gift')}
                          >
                            {'ซื้อให้เพื่อน'}
                          </Button>
                        </Box>
                        {
                          Object.keys(cardId).length === 0 && (
                            <Box flex={1} marginLeft={1}>
                              <Button
                                fullWidth
                                size='large'
                                onClick={() => onClick(val, 'self')}
                              >
                                {'ซื้อเลย'}
                              </Button>
                            </Box>
                          )
                        }
                      </Box>
                      {
                        Object.keys(cardId).length !== 0 && (
                          <span style={{ fontSize: 10, color: 'red' }}>
                            * หากคุณเป็นสมาชิกอยู่แล้ว จะไม่สามารถซื้อเพิ่มให้ตัวเองได้จนกว่าจะหมดอายุ
                          </span>
                        )
                      }
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </Layout>
  );
}